import { Middleware } from "redux";
import { v4 as uuid } from "uuid";

import { StorageKey } from "../../../../constants/storageKeys";
import { GTM, GTMPayloadWithoutCommon } from "../../../../infra/gtm";
import { RootState } from "../../../reducer";
import { initializeUserAction } from "../../user/actions/InitializeMeAction";
import { sendToGTMAction } from "../actions/SendToGTMAction";

// returnの型を Middleware<{}, RootState> にする
// middlewares.push(createSendToGTMMiddleware(gtm));で型エラーがでるので解決する
export function createSendToGTMMiddleware(gtm: GTM): Middleware {
  const queue: GTMPayloadWithoutCommon[] = [];
  const sid = localStorage.getItem(StorageKey.GTM_SID) || uuid();

  if (!localStorage.getItem(StorageKey.GTM_SID)) {
    localStorage.setItem(StorageKey.GTM_SID, sid);
  }
  return (store) => (next) => (action) => {
    const { user } = store.getState() as RootState;

    if (sendToGTMAction.match(action)) {
      const isFetchingMe = user.me.requesting;

      // user 取得中の場合はエンキューして待ち
      // 認証が必要なページのみユーザー取得が実行される
      if (isFetchingMe) {
        queue.push(action.payload);
      } else {
        gtm.send({
          ...action.payload,
          sid,
          current_user_id: user.me.data ? user.me.data.id : null,
          current_company_id: user.currentCompany?.company
            ? user.currentCompany.company.id
            : null,
        });
      }
    }

    // user の取得が完了したらキューに詰めたpayloadsをsend
    if (initializeUserAction.done.match(action)) {
      while (queue.length) {
        gtm.send({
          ...(queue.shift() as GTMPayloadWithoutCommon),
          sid,
          current_user_id: user.me.data ? user.me.data.id : null,
          current_company_id: user.currentCompany?.company
            ? user.currentCompany.company.id
            : null,
        });
      }
    }

    return next(action);
  };
}
